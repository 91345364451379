<template>
  <v-app>
    <v-main>
      <v-container>
        <UniversalWidget :projUUID="projUUIDout" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UniversalWidget from "./components/UniversalWidget_v3.vue";

export default {
  props: {
    projUUID: String,
  },
  components: {
    UniversalWidget,
  },
  data() {
    return {
      projUUIDout: this.projUUID,
    };
  },
};
</script>

<style>
 .v-application--wrap{
    min-height: unset;
  }
</style>