import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const widgetContainers = document.querySelectorAll('.widget-container');
  
  widgetContainers.forEach((container) => {

  const projUUID = container.getAttribute('data-projUUID');

  new Vue({
    vuetify,
    render: h => h(App, {
      props: { projUUID },
    }),
  }).$mount(container)

  });
});

