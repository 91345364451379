<template>
  <div>
    <v-card
      class="d-flex justify-space-between"
      flat
      
    >
      <v-card
        
        flat
        style="text-align: left; 
          margin-left: 10px; 
          margin-right: 8px; 
          margin-top: 8px; 
          margin-bottom: 5px;
          font-family: 'Montserrat',Arial,sans-serif; line-height: 1.55; 
          font-weight: 600; 
          font-size: 16px; 
          color: #666666;"
      >
        Собрано
      </v-card>
      <v-card
       
        flat
        style="text-align: left; 
          margin-left: 10px; 
          margin-right: 8px;  
          margin-top: 8px; 
          margin-bottom: 5px; 
          color: #666666; 
          font-size: 16px; 
          font-family: 'Montserrat',Arial,sans-serif; 
          font-weight: 600;"
      >
      Не хватает
      </v-card>
    </v-card>
    <v-card
      class="d-flex justify-space-between"
      flat
    >
      
      <v-card
        flat
        style="text-align: right; 
          margin-left: 8px; 
          margin-right: 10px; 
          color: #60a194; 
          font-size: 25px; 
          font-family: 'Montserrat',Arial,sans-serif; line-height: 1;  
          font-weight: 700;"
      >
      {{ GetValue.toLocaleString(undefined, 2) == 0 ? '' : GetValue.toLocaleString(undefined, 2) + '₽' }}
      </v-card>
      <v-card
        flat
        style="text-align: right; 
          margin-left: 8px;
          margin-right: 10px; 
          color: #ed2023; 
          font-size: 25px; 
          font-family: 'Montserrat',Arial,sans-serif; line-height: 1; 
          font-weight: 700;"
      >
      {{ GetValueMinus.toLocaleString(undefined, 2) == 1 ? '' : GetValueMinus.toLocaleString(undefined, 2) + '₽' }}
      </v-card>
    </v-card>
    <v-progress-linear 
      style="margin-top: 15px;"
      :value="progress" 
      color="#ed2023" 
      backgroundColor="#eeeeee" 
      height="20" 
      :style="{ borderRadius: '10px' }">
    </v-progress-linear>
    <v-card
      class="d-flex justify-center"
      flat
    >
      <v-card
        flat
        style="text-align: center; 
          margin-top: 15px;
          color: #666666; 
          font-size: 16px; 
          font-family: 'Montserrat',Arial,sans-serif; line-height: 1; 
          font-weight: 600;"
      >
      Всего требуется
      </v-card>
    </v-card>
    <v-card
      class="d-flex justify-center"
      flat
    >
      <v-card
        flat
        style="text-align: center; 
          margin-top: 8px;
          color: #000000; 
          font-size: 25px; 
          font-family: 'Montserrat',Arial,sans-serif; line-height: 1; 
          font-weight: 700;"
      >
      {{ EndValue.toLocaleString(undefined, 2) == 1 ? '' : EndValue.toLocaleString(undefined, 2) + '₽' }}
      </v-card>
    </v-card>
  </div>
</template>

<script>

import axios from 'axios'
import { Buffer } from 'buffer';

export default {
  props: {
    projUUID: String,
  },
  data() {
    return {
      progress: 0,
      EndValue: 1,
      EndValueTicker: 1,
      GetValue: 0,
      GetValueMinus: 0,
      GetValueTicker: 0,
      GetTitleValue: 'Загрузка ...',
      GetPaymentsName: '',
      EndValueProc: 0
    };
  },
  async mounted(){
    let data = []

    await axios({
        method: 'POST',
        headers: {
            'api_key': 'ANALYTICSNODE.VSEVMESTE.2a284685545668db8a8f7584f14879a6e3216516846518f7584f1',
            'projUUID': this.projUUID,
            'Content-Type': 'application/json'
        },
        url: 'https://api.analyticsnode.ru/vsevmesteNode/getProjById',
        data: data

    })
    .then(async (response) => {
        //console.log(response.data)

        const pgSQLData = JSON.parse(Buffer.from(response.data, 'base64').toString('utf-8'));
        
        //console.log(pgSQLData[0].strPaymentsName)
        this.GetPaymentsName = pgSQLData[0].strPaymentsName.trim()
        //console.log(this.GetPaymentsName)

        this.EndValue = ~~pgSQLData[0].numFullPrice;

        await axios({
          method: 'POST',
          headers: {
              'api_key': 'ANALYTICSNODE.VSEVMESTE.2a284685545668db8a8f7584f14879a6e3216516846518f7584f1',
              'PaymentsName': Buffer.from(this.GetPaymentsName, 'utf-8').toString('base64'),
              'Content-Type': 'application/json'
          },
          url: 'https://api.analyticsnode.ru/vsevmesteNode/getSumByPaymentsName',
          data: data

        })
        .then(async (response) => {

            //console.log(this.EndValue)
            const pgSQLDataSum = JSON.parse(Buffer.from(response.data, 'base64').toString('utf-8'));
            //console.log(pgSQLDataSum[0].earned)

            this.GetValue = ~~pgSQLDataSum[0].earned
            
            if(this.EndValue.toString().length<=6){
              this.EndValueTicker = this.EndValue/1000
              this.GetValueTicker = this.GetValue/1000
            }
            else
            {
              this.EndValueTicker = this.EndValue/1000/1000
              this.GetValueTicker = this.GetValue/1000/1000
            }

            this.GetTitleValue = this.GetValue.toLocaleString(undefined, 2) + " из " + this.EndValue.toLocaleString(undefined, 2);

            this.GetValueMinus = this.EndValue - this.GetValue;

            let ProcFr = (this.EndValue - this.GetValue)/this.EndValue;
            this.EndValueProc = Math.round(100 - (ProcFr*100));
            //console.log(this.EndValueProc)
            this.progress = this.EndValueProc;
            
        })
        .catch((response) => {
            //console.log(response)
            this.GetTitleValue = 'Ошибка обработки'
            var err = Object.assign({}, response)
            console.log('error', err.response);
        });
          
    })
    .catch((response) => {
        //console.log(response)
        this.GetTitleValue = 'Ошибка обработки'
        var err = Object.assign({}, response)
        console.log('error', err.response);
    });

    
    //console.log(this.EndValueTicker);
  },
  methods: {
    
  },
};
</script>



